import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useChatticStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      bannerCase: file(
        relativePath: { eq: "case-study/chattic/collectible-marketplace.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredOne: file(
        relativePath: {
          eq: "case-study/chattic/collectible-platform-design.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredTwo: file(
        relativePath: { eq: "case-study/chattic/collectible-listings.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredThree: file(
        relativePath: {
          eq: "case-study/chattic/collectible-appraisal-service.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFour: file(
        relativePath: {
          eq: "case-study/chattic/collectible-insurance-service.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFive: file(
        relativePath: {
          eq: "case-study/chattic/search-for-users-and-items.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSix: file(
        relativePath: {
          eq: "case-study/chattic/collectible-marketplace-shipping-aggregator.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSeven: file(
        relativePath: {
          eq: "case-study/chattic/collectible-marketplace-checkout-process.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredEight: file(
        relativePath: {
          eq: "case-study/chattic/follow-message-comment-features.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      videoCaseImage: file(
        relativePath: { eq: "case-study/chattic/video-case.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)
  return query
}

export default useChatticStaticQuery
