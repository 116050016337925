// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bn_kr";
export var caseStudyBackground__bgColor = "bn_kn";
export var caseStudyBackground__lineColor = "bn_kp";
export var caseStudyCta__bgColor = "bn_kG";
export var caseStudyHead__imageWrapper = "bn_kk";
export var caseStudyProjectsSection = "bn_ks";
export var caseStudyQuote__bgLight = "bn_kt";
export var caseStudyQuote__bgRing = "bn_km";
export var caseStudyTechSection = "bn_kq";
export var caseStudyVideo = "bn_kB";
export var caseStudyVideo__ring = "bn_kv";
export var caseStudy__bgDark = "bn_kh";
export var caseStudy__bgLight = "bn_kg";